<template>
    <Layout ref="layout">
        <ModalChangePassword/>
        <ModalDeleteAccount/>
        <ModalEnable2FA/>
        <ModalDisable2FA/>
        <ModalQR2FA/>
        <ModalCode2FA/>
        <ModalBackupCode2FA/>
        <ModalViewBackupCode2FA/>
        <div class="setting-plan-container">
            <div class="col-md-6">
                <p class="setting-plan-title">Profile</p>
                <form action="" @submit.prevent="submit()">
                    <div class="settings-name mb-3">
                        <label for="email-profile" class="">Email</label>
                        <input
                            disabled
                            v-model="newEmail"
                            type="text"
                            id="email-profile"
                            class="form-control input-profile-v3">
                    </div>
                    <div class="settings-name mb-3">
                        <label for="first-name-profile" class="">First Name</label>
                        <div class="input-state-profile">
                            <input
                                @focus="focusFirstName()"
                                @focusout="focusoutFirstName()"
                                v-model.trim="$v.newFirstName.$model"
                                type="text"
                                id="first-name-profile"
                                :class="{'is-invalid': validationStatus($v.newFirstName)}"
                                class="form-control input-profile-v3">
                            <div class="invalid-feedback">
                                <span v-if="!$v.newFirstName.maxLength" id="first-name-error" class="required-color error-message-v3">First name max. length is 100 characters.</span>
                                <span v-if="!$v.newFirstName.required" id="first-name-empty" class="required-color error-message-v3">First name cannot be empty.</span>
                            </div>
                        </div>
                    </div>
                    <div class="settings-name mb-3">
                        <label for="last-name-profile" class="">Last Name</label>
                        <div class="input-state-profile">
                        <input
                            @focus="focusLastName()"
                            @focusout="focusoutLastName()"
                            v-model.trim="$v.newLastName.$model"
                            type="text"
                            id="last-name-profile"
                            :class="{'is-invalid': validationStatus($v.newLastName)}"
                            class="form-control input-profile-v3">
                            <div class="invalid-feedback">
                                <span v-if="!$v.newLastName.maxLength" id="last-name-error" class="required-color error-message-v3">Last name max. length is 100 characters.</span>
                                <span v-if="!$v.newLastName.required" id="last-name-empty" class="required-color error-message-v3">Last name cannot be empty.</span>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="settings-name-btn mb-3">
                  <button class="btn-submit-settings-plan"
                    @click="submit()"
                    :disabled="$v.$invalid || 
                    (isFirstNameFocus == false && isLastNameFocus == false) &&
                    firstname == newFirstName && lastname == newLastName
                    ">Save</button>
                </div>
                <hr class="horizontal-account-profile">
                <p class="setting-plan-title">Security</p>
                <div class="settings-name mb-3">
                    <div>
                        <p class="title-modal-change-password">Password</p>
                    </div>
                    <div class="w-312">
                        <button class="btn-submit-settings-plan" @click="changePassword()">Change Password</button>
                    </div>
                </div>
                <div class="settings-name mb-3">
                    <div>
                        <p class="label-settings-name">Two-factor auth (2FA)</p>
                    </div>
                    <div v-if="status2fa == false" class="w-312">
                        <button class="btn-submit-settings-plan-enable" @click="enableMFA()">Enable</button>
                    </div>
                    <div v-if="status2fa == true" class="w-312">
                        <button class="btn-submit-settings-plan" @click="disableMFA()">Disable</button>
                    </div>
                </div>
                <div v-if="status2fa == true" class="settings-name mb-3">
                    <div>
                        <p class="label-settings-name">Backup codes</p>
                    </div>
                    <div class="w-312">
                        <button class="btn-submit-settings-plan" v-b-modal.modal-view-backup-2fa>View</button>
                    </div>
                </div>
                <hr class="horizontal-account-profile">
                <p class="setting-plan-title">Delete account</p>
                <div class="settings-name">
                    <div>
                        <p class="label-settings-name">Delete your account</p>
                    </div>
                    <div class="w-312">
                        <button class="btn-submit-settings-plan" @click="deleteAccount()">Delete Account</button>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main.vue";
import { required, maxLength, email } from 'vuelidate/lib/validators'
import ModalChangePassword from "../../../../components/modalChangePasswordV3.vue"
import ModalDeleteAccount from "../../../../components/modalDeleteAccount.vue";
import ModalEnable2FA from "../../../../components/widgets/enable2FA.vue";
import ModalDisable2FA from "../../../../components/widgets/disable2FA.vue";
import ModalQR2FA from "../../../../components/widgets/modalQR2FA.vue";
import ModalCode2FA from "../../../../components/widgets/modalCode2FA.vue";
import ModalBackupCode2FA from "../../../../components/widgets/modalBackupCode2FA.vue";
import ModalViewBackupCode2FA from "../../../../components/widgets/modalViewBackupCode.vue";

export default {
    data(){
        return {
            newFirstName: '',
            newLastName: '',
            newEmail: '',
            isFirstNameFocus: false,
            isLastNameFocus: false
        }
    },
    components: {
        Layout,
        ModalChangePassword,
        ModalDeleteAccount,
        ModalEnable2FA,
        ModalDisable2FA,
        ModalQR2FA,
        ModalCode2FA,
        ModalBackupCode2FA,
        ModalViewBackupCode2FA
    },
    validations: {
        newFirstName: {
            required,
            maxLength: maxLength(100)
        },
        newLastName: {
            required,
            maxLength: maxLength(100)
        },
        newEmail: {
            required,
            email,
            isUnique (value) {
                if (value === '') return true
                var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(email_regex.test(value))
                }, 350 + Math.random()*300)
                })
            }
        },
    },
    mounted() {
        this.reset()
        this.$store.dispatch("user/getLimit", null)
    },
    computed: {
        status2fa(){
            return this.$store.getters['user/enabled2fa']
        },
        firstname(){
            return this.$store.getters['user/firstName'];
        },
        lastname(){
            return this.$store.getters['user/lastName'];
        },
        loading(){
            return this.$store.getters['user/loading'];
        },
        nama() {
            return this.$store.getters['user/firstName']
        },
        email() {
            return this.$store.getters['user/email']
        },
        message() {
            return this.$store.getters['error/message']
        },
        success() {
            return this.$store.getters['error/success']
        },
        error() {
            return this.$store.getters['error/error']
        }
    },
    methods: {
        deleteAccount() {
            this.$ga.event(process.env.VUE_APP_GA_SET_DEL_ACCOUNT, 'Settings - Delete Account', 'Settings Primary')
            this.$bvModal.show('modal-delete-account-v3')
        },
        changePassword() {
            this.$ga.event(process.env.VUE_APP_GA_SET_CHANGE_PWD, 'Settings - Change Pwd', 'Settings Primary')
            this.$bvModal.show('modal-change-password-v3')
        },
        enableMFA() {
            this.$ga.event(process.env.VUE_APP_GA_SET_2FE_ACTIVE, 'Settings - Enable 2FA', 'Settings Primary')
            this.$bvModal.show('modal-enable-2fa')
        },
        disableMFA() {
            this.$bvModal.show('modal-disable-2fa')
        },
        submit(){
            this.$store.dispatch("user/editPassword",
                {
                firstname: this.newFirstName,
                lastname: this.newLastName,
                email: this.newEmail,
            })
            .then(()=>{
            this.$toasted.show('New profile has been saved.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
            return true;
            });
        },
        validationStatus(validation) {
            return typeof validation != undefined ? validation.$error: false
        },
        reset() {
            this.newFirstName = this.firstname
            this.newLastName = this.lastname
            this.newEmail = this.email
        },
        focusFirstName(){
            this.isFirstNameFocus = true
        },
        focusoutFirstName(){
            this.isFirstNameFocus = false
        },
        focusLastName(){
            this.isLastNameFocus = true
        },
        focusoutLastName(){
            this.isLastNameFocus = false
        }
    }
}
</script>

<style>

</style>